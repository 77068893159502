@import "../../constants//styles.scss";

.ProjectPage {
  @include mobile {
    > * {
      text-align: left;
    }
    .technology {
      margin-bottom: 40px;
    }
  }
  .title-container {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  .margin-container {
    padding-top: 0.6%;
    padding-bottom: 0.6%;
  }
  .paragraph-container {
    width: 100%;
    padding-top: 0.7vw;
    padding-bottom: 0.7vw;
    box-sizing: border-box;
    text-align: left;
    font-size: 18px;
    color: $dark-grey;
    line-height: 1.7;
  }
  img {
    text-align: center;
    width: 100%;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .video {
    text-align: center;
    width: 100%;
    height: 50vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}
