.Main {
  height: 100%;
  width: 100%;
  align-items: center;

  > * {
    text-align: center;
  }

  .title {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
