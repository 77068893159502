.Footer {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  text-align: center;
  bottom: 0;
  .basetext2 {
    margin: 0 auto;
  }
}
